<template>
  <div class="password-reset-verification">
    <div v-if="verifying" class="password-reset-verification-verifying">
      <a-spin :spinning="verifying" size="large" :tip="labels.verifying" />
    </div>
    <div v-else-if="has_error" class="password-reset-verification-content">
      <font-awesome-icon
        :icon="['fas', 'times-circle']"
        size="5x"
        :class="'danger'"
      />
      <p>{{ message }}</p>
      <div>
        <router-link class="btn-login" :to="{ name: 'login' }">{{
          labels.goToLogin
        }}</router-link>
      </div>
      <div>
        <router-link class="btn-home" :to="{ name: 'home' }">{{
          labels.goToHome
        }}</router-link>
      </div>
    </div>
    <PasswordResetForm v-else />
  </div>
</template>
<script>
import loadingMixin from "@/mixins/loadingMixin";
import labels from "@/utils/labels";
import { mapActions } from "vuex";
import PasswordResetForm from "@/components/PasswordResetForm.vue";
import { pageTitle } from "../../utils/utils";
export default {
  components: { PasswordResetForm },
  name: "PasswordResetVerification",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels.passwordReset.verification),
    meta: [
      {
        name: "title",
        content: labels.appName,
      },
      {
        name: "description",
        content: labels.passwordReset.verification,
      },
    ],
  },
  data() {
    return {
      labels: labels.passwordResetForm,
      verifying: true,
      message: "",
      has_error: false,
    };
  },
  created() {
    this.verify();
  },
  methods: {
    ...mapActions("clientAuth", ["handlePasswordResetCodeVerify"]),
    async verify() {
      try {
        this.verifying = true;
        let code = this.$route.params.code;
        if (!code) {
          this.has_error = true;
          this.message = this.labels.invalidToken;
          this.verifying = false;
          return;
        }
        await this.handlePasswordResetCodeVerify(code);
        this.has_error = false;
        this.message = "";
      } catch (error) {
        this.message =
          error?.response?.data?.message ||
          error?.response?.message ||
          error.message;
        this.has_error = true;
      } finally {
        this.verifying = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.password-reset-verification {
  text-align: center;
  padding: 20px;
  min-height: 100vh;
  width: 100%;
  background-color: color(c-secondary);
  color: color(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Exo 2", sans-serif;
  user-select: none;
  &-content {
    width: 600px;
    padding: 70px;
    background-color: color(c-primary);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    gap: 10px;
    h1 {
      font-size: 3.5rem;
      color: color(c-hover);
      margin-bottom: 0;
    }
    p {
      font-size: 1.45rem;
      margin-bottom: 0px;
    }
    div {
      width: 210px;
      height: 40px;
      display: flex;
      flex-direction: column;
      .btn-home {
        background-color: transparent;
        border: 1px solid color(c-hover);
        color: color(c-hover);
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 0.85rem;
        margin-top: 20px;
        transition: all 0.5s ease;
        font-weight: 600;
        &:hover {
          background-color: color(c-hover);
          color: color(c-primary);
          transition: all 0.5s ease;
        }
      }
      .btn-login {
        @extend .btn-home;
        background-color: color(c-hover);
        color: color(c-primary);
      }
    }
    .fa-check-circle,
    .fa-times-circle {
      margin-bottom: 10px;
      color: color(c-hover);
      font-weight: 400;
      &.danger {
        color: color(danger) !important;
      }
    }
  }
  .ant-spin {
    color: color(--white);
  }
}
</style>
